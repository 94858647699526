<template>
  <div class="register">
    <Heading noDecoration type="h3" text-align="center">
      Register attendee
    </Heading>
    <SectionWithBg
      min-width-heading
      heading-top
      heading-position="left"
      growing-body
    >
      <template #heading>
        {{ ticketType }}
      </template>
      <template #body>
        <div v-if="regSuccess" class="register__success">
          Thank you! <br />
          You have successfully registered <br />
          <Link :link="`/${$route.params.id}/ticket/${successId}`">
            Click to see ticket
          </Link>
          <!--          <br />
                    <a :href="`${baseUrl}virtualconf/#/${$route.params.id}`">
                      Login to Virtual Conference Platform
                    </a>-->
        </div>
        <div v-else class="register__wrapper">
          <form @submit.prevent="submitRegistration">
            <Input
              v-for="(field, name) in form"
              :key="name"
              v-model="field.value"
              :error="field.error"
              :name="name"
              :label="field.label"
              :is-multiline="field.isMultiline"
              :disabled="field.disabled"
              :rows="field.rows"
              type="text"
              @input="clearError(name, form)"
              @blur="validateField(name, form)"
            />

            <input
              type="checkbox"
              id="agreeToTerms"
              v-model="agreeToTerms"
              @input="termsError = null"
            />
            <label for="agreeToTerms">
              I have read and agree to the
              <Link
                link="/attendee-privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                attendee privacy policy
              </Link>
            </label>
            <br />
            <input type="checkbox" id="optin" v-model="optin" />
            <label for="optin">
              I want to receive communications from sponsors including ticket
              upgrades, workshop information, and other promotional materials
            </label>
            <transition name="fade">
              <div v-show="termsError" class="input__error">
                {{ termsError }}
              </div>
            </transition>
            <transition name="fade">
              <Error v-if="regError" class="register__error">
                <template #heading
                  >{{ regError || "Sorry, something went wrong" }}
                </template>
                <template #body>
                  Please try again or contact us at
                  <a
                    :href="`mailto:${config.helpEmail}?subject=Attendee Register Error`"
                    class="error__link"
                  >
                    {{ config.helpEmail }}
                  </a>
                </template>
              </Error>
            </transition>
            <Preloader :loading="loading" />
            <Button v-show="!loading" class="register__button">redeem</Button>
          </form>
        </div>
      </template>
    </SectionWithBg>
  </div>
</template>

<script>
import Heading from "@/components/common/Heading";
import SectionWithBg from "@/components/common/SectionWithBg";
import Preloader from "@/components/common/Preloader";
import Input from "@/components/common/Input";
import Link from "@/components/common/Link";
import Button from "@/components/common/Button";
import Error from "@/components/common/Error";
import {
  validateField,
  validateForm,
  clearError,
} from "@/assets/js/validation";
import { transformForm } from "@/assets/js/utils";
import { mapActions, mapGetters } from "vuex";
import config from "../../../public/config.json";

export default {
  name: "RegisterAttendee",
  props: {},
  components: {
    Heading,
    Preloader,
    SectionWithBg,
    Input,
    Link,
    Button,
    Error,
  },
  data: () => ({
    config,
    loading: false,
    ticketType: "",
    ticketFeatures: [],
    regError: null,
    regSuccess: false,
    successId: null,
    form: {
      first_name: {
        value: "",
        error: "",
        rules: ["required"],
        label: "First Name",
      },
      last_name: {
        value: "",
        error: "",
        rules: ["required"],
        label: "Last Name",
      },
      organization: {
        value: "",
        error: "",
        rules: ["required"],
        label: "Organization",
      },
      job_title: {
        value: "",
        error: "",
        rules: ["required"],
        label: "Job Title",
      },
      phone: {
        value: "",
        error: "",
        rules: ["required", "phone"],
        label: "Phone",
      },
    },
    agreeToTerms: false,
    termsError: null,
    optin: false,
  }),
  mounted() {
    const ticket = JSON.parse(localStorage.getItem("ticket"));
    this.ticketType = ticket.title;
    this.ticketFeatures = ticket.description;
  },
  computed: {
    ...mapGetters({
      userEmail: "getUserEmail",
      userCode: "getUserCode",
    }),
    baseUrl() {
      return process.env.VUE_APP_BASE_URL.split("api/")[0];
    },
  },
  methods: {
    transformForm,
    validateField,
    validateForm,
    clearError,
    ...mapActions(["POST", "GET"]),
    submitRegistration() {
      this.regError = null;
      const isValid = validateForm(this.form);
      if (!this.agreeToTerms)
        this.termsError = "Please read and agree to attendee privacy policy";
      if (!isValid || !this.agreeToTerms) return;

      this.loading = true;
      const formData = this.transformForm(this.form);
      formData.code = this.userCode;
      formData.email = this.userEmail;
      formData.optin = this.optin;

      this.POST({
        formRoute: false,
        route: `/ticketing/public/event/${this.$route.params.id}/code/${formData.code}/redeem`,
        data: formData,
      })
        .then((res) => {
          this.successId = res.data.id;
          this.loading = false;
          this.regSuccess = true;
          this.$store.commit("resetTicketState");
        })
        .catch((err) => {
          this.regError = err.response.data.msg;
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/_variables";

.register {
  padding-top: 106px;

  &__wrapper {
    width: 80%;
  }

  &__button {
    width: 100%;
    margin-top: 24px;
    margin-bottom: 16px;
  }

  &__error {
    text-align: center;
  }

  &__success {
    width: 80%;
    text-align: center;
    font-size: 1.3rem;
  }
}
</style>
